/*
 * easy-autocomplete
 * jQuery plugin for autocompletion
 * 
 * @author Łukasz Pawełczak (http://github.com/pawelczak)
 * @version 1.3.5
 * Copyright  License: 
 */

.easy-autocomplete {
  /* Theme Square */
  /* Round */
  /* Funky */
  /* Solid */
  /* Plate */
}
.easy-autocomplete.eac-square {
  font-family: "Roboto", "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.easy-autocomplete.eac-square input {
  border: 1px solid #7f8c8d;
  border-radius: 0;
  color: #7f8c8d;
  font-family: inherit;
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  margin: 0;
  min-width: 350px;
  padding: 12px 43px 12px 15px;
}
.easy-autocomplete.eac-square ul {
  border-color: #7f8c8d;
  border-top: 0;
  box-shadow: none;
  top: 0;
}
.easy-autocomplete.eac-square ul li, .easy-autocomplete.eac-square ul .eac-category {
  border-color: #7f8c8d;
  font-family: inherit;
  font-size: 16px;
  font-weight: 300;
  padding: 6px 12px;
  transition: all 0.4s ease 0s;
}
.easy-autocomplete.eac-square ul li *, .easy-autocomplete.eac-square ul .eac-category * {
  font-weight: 300;
}
.easy-autocomplete.eac-square ul li.selected, .easy-autocomplete.eac-square ul .eac-category.selected {
  background-color: #f1f1f1;
  font-weight: 300;
}
.easy-autocomplete.eac-square ul li b, .easy-autocomplete.eac-square ul .eac-category b {
  font-weight: 600;
}
.easy-autocomplete.eac-round {
  font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.easy-autocomplete.eac-round input {
  border: 2px solid #888;
  border-radius: 10px;
  color: #888;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  min-width: 300px;
  padding: 10px;
}
.easy-autocomplete.eac-round input:hover, .easy-autocomplete.eac-round input:focus {
  border-color: #3079ed;
}
.easy-autocomplete.eac-round ul {
  background: none;
  border-color: #888;
  border-width: 0;
  box-shadow: none;
  min-width: 300px;
  top: 20px;
}
.easy-autocomplete.eac-round ul li, .easy-autocomplete.eac-round ul .eac-category {
  background: #fff;
  border-color: #3079ed;
  border-width: 0 2px;
  font-size: 14px;
  padding: 8px 12px;
  transition: all 0.4s ease 0s;
}
.easy-autocomplete.eac-round ul li.selected, .easy-autocomplete.eac-round ul .eac-category.selected {
  background-color: #d4e3fb;
}
.easy-autocomplete.eac-round ul li:first-child, .easy-autocomplete.eac-round ul .eac-category:first-child {
  border-radius: 10px 10px 0 0;
  border-width: 2px 2px 0;
}
.easy-autocomplete.eac-round ul li:last-child, .easy-autocomplete.eac-round ul .eac-category:last-child {
  border-radius: 0 0 10px 10px;
  border-width: 0 2px 2px;
}
.easy-autocomplete.eac-round ul li b, .easy-autocomplete.eac-round ul .eac-category b {
  font-weight: 700;
}
.easy-autocomplete.eac-funky {
  font-family: "Inconsolata", "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.easy-autocomplete.eac-funky input {
  background: #07ED89;
  border: 0;
  border-radius: 0;
  color: #ED076B;
  font-family: inherit;
  font-size: 18px;
  min-width: 240px;
  padding: 12px;
}
.easy-autocomplete.eac-funky ul {
  background: transparent;
  border: 0;
}
.easy-autocomplete.eac-funky ul li, .easy-autocomplete.eac-funky ul .eac-category {
  background: transparent;
  border: 0;
  color: #ED076B;
  font-size: 18px;
  padding: 4px 12px;
  transition: all 0.4s ease 0s;
}
.easy-autocomplete.eac-funky ul li.selected, .easy-autocomplete.eac-funky ul .eac-category.selected {
  color: #8907ED;
}
.easy-autocomplete.eac-solid input {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) inset;
  border-color: #ddd;
  color: #666;
  font-size: 13px;
  min-height: 0;
  padding: 6px 12px;
}
.easy-autocomplete.eac-solid ul li, .easy-autocomplete.eac-solid ul .eac-category {
  transition: all 0.4s ease 0s;
}
.easy-autocomplete.eac-solid ul li.selected, .easy-autocomplete.eac-solid ul .eac-category.selected {
  background-color: #f2f2f2;
}
.easy-autocomplete.eac-plate {
  font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.easy-autocomplete.eac-plate input {
  font-size: 13px;
  padding: 6px 12px;
}
.easy-autocomplete.eac-plate ul {
  background: #b5b5b5;
  border-radius: 6px;
  border-width: 0;
  top: 10px;
}
.easy-autocomplete.eac-plate ul li, .easy-autocomplete.eac-plate ul .eac-category {
  background: #b5b5b5;
  color: #FAFAFA;
  font-size: 12px;
  transition: all 0.4s ease 0s;
}
.easy-autocomplete.eac-plate ul li b, .easy-autocomplete.eac-plate ul .eac-category b {
  color: #ffd464;
}
.easy-autocomplete.eac-plate ul li.selected, .easy-autocomplete.eac-plate ul .eac-category.selected {
  background: #656565;
}
.easy-autocomplete.eac-plate ul li:first-child, .easy-autocomplete.eac-plate ul .eac-category:first-child {
  border-radius: 6px 6px 0 0;
}
.easy-autocomplete.eac-plate ul li:last-child, .easy-autocomplete.eac-plate ul .eac-category:last-child {
  border-radius: 0 0 6px 6px;
}
.easy-autocomplete.eac-plate-dark {
  font-family: "Open Sans", "Helvetica Neue",Helvetica,Arial,sans-serif;
}
.easy-autocomplete.eac-plate-dark input {
  font-size: 13px;
  padding: 6px 12px;
}
.easy-autocomplete.eac-plate-dark ul {
  background: #212121;
  border-radius: 6px;
  border-width: 0;
  top: 10px;
}
.easy-autocomplete.eac-plate-dark ul li, .easy-autocomplete.eac-plate-dark ul .eac-category {
  background: #212121;
  color: #FAFAFA;
  font-size: 12px;
  transition: all 0.4s ease 0s;
}
.easy-autocomplete.eac-plate-dark ul li b, .easy-autocomplete.eac-plate-dark ul .eac-category b {
  color: #ffd464;
}
.easy-autocomplete.eac-plate-dark ul li.selected, .easy-autocomplete.eac-plate-dark ul .eac-category.selected {
  background: #656565;
}
.easy-autocomplete.eac-plate-dark ul li:first-child, .easy-autocomplete.eac-plate-dark ul .eac-category:first-child {
  border-radius: 6px 6px 0 0;
}
.easy-autocomplete.eac-plate-dark ul li:last-child, .easy-autocomplete.eac-plate-dark ul .eac-category:last-child {
  border-radius: 0 0 6px 6px;
}

/*# sourceMappingURL=easy-autocomplete.themes.css.map */
