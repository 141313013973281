/* Common, default styles for the notification box */

.ns-box {
	position: fixed;
	background: rgba(191, 35, 98, 0.73);
	padding: 22px;
	line-height: 1.4;
	z-index: 10001;
	right:0px;
	pointer-events: none;
	color: #fff;
	font-size: 90%;
	font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
}


.ns-box.ns-show {
	pointer-events: auto;
}

.ns-box a {
	color: inherit;
	opacity: 0.7;
	font-weight: 700;
}

.ns-box a:hover,
.ns-box a:focus {
	opacity: 1;
}

.ns-box p {
	margin: 0;
}

.ns-box.ns-show,
.ns-box.ns-visible {
	pointer-events: auto;
}

.ns-close {
	width: 20px;
	height: 20px;
	position: absolute;
	right: 4px;
	top: 4px;
	overflow: hidden;
	text-indent: 100%;
	cursor: pointer;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.ns-close:hover, 
.ns-close:focus {
	outline: none;
}

.ns-close::before,
.ns-close::after {
	content: '';
	position: absolute;
	width: 3px;
	height: 60%;
	top: 50%;
	left: 50%;
	background: #6e6e6e;
}

.ns-close:hover::before,
.ns-close:hover::after {
	background: #fff;
}

.ns-close::before {
	-webkit-transform: translate(-50%,-50%) rotate(45deg);
	transform: translate(-50%,-50%) rotate(45deg);
}

.ns-close::after {
	-webkit-transform: translate(-50%,-50%) rotate(-45deg);
	transform: translate(-50%,-50%) rotate(-45deg);
}